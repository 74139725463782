@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

.global-px {
  @apply mx-auto px-3 max-w-sm sm:max-w-lg md:max-w-3xl lg:max-w-5xl xl:max-w-6xl;
}

@font-face {
  font-family: 'Comfortaa-Bold';
  src: url('../assets/fonts/Comfortaa-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa-Light';
  src: url('../assets/fonts/Comfortaa-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa-Regular';
  src: url('../assets/fonts/Comfortaa-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa-Medium';
  src: url('../assets/fonts/Comfortaa-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa-SemiBold';
  src: url('../assets/fonts/Comfortaa-SemiBold.ttf') format('truetype');
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}